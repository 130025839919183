<template>
  <div id="body">
    <div class="pwd">
      <p class="title">修改密码</p>
      <div class="Box">
        <!-- <el-input v-model="password" placeholder="请输入新密码" style="width:300px"></el-input> -->
        <!-- <el-button type="primary" style="margin-left:6px" @click="changepwd">修改</el-button> -->
        <el-form :model="Password" ref="articleForm">
          <el-form-item label="原始密码">
            <el-input
                v-model="Password.old"
              autocomplete="off"
              type="password"
              placeholder="请输入原始密码"
              style="width: 300px; float: left"
              ></el-input>
          </el-form-item>

           <el-form-item label="新密码">
            <el-input
                v-model="Password.new"
              autocomplete="off"
              type="password"
              placeholder="请输入新密码"
              style="width: 300px; float: left;margin-left:13px"
              ></el-input>
          </el-form-item>
          <el-form-item label="新密码">
            <el-input
                v-model="Password.again"
              autocomplete="off"
              type="password"
              placeholder="再次输入新密码"
              style="width: 300px; float: left;margin-left:13px"
              @blur="verify"
              ></el-input>
          </el-form-item>
          <el-button type="primary" class="btn" style="margin-left:5px" @click="changepwd()">修改</el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5'
import { modifyPass } from '@/api/user.js'
export default {
  data () {
    return {
      Password: {
        old: '',
        new: '',
        again: ''
      }
    }
  },
  mounted () {},
  methods: {
    changepwd () { // 修改密码
      const data = {
        oldPass: md5(this.Password.old),
        newPass: md5(this.Password.new)
      }
      if (this.Password.new != this.Password.again) {
        this.$message('两次密码不一致')
      } else {
        modifyPass(data).then(res => {
          // console.log(res)
          if (res.status.code == 1) {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.Password = {}
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    verify () {
      if (this.Password.new != this.Password.again) {
        this.$message('两次密码不一致')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#body {
  // height: 100%;
  overflow: hidden;
  background: white;
}
.Box{
    width: 400px;
    margin: 0 auto;
}
</style>
